import React from 'react'
import './UserNotification.scss'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getIcon } from 'utils/iconUtils'

const NotificationDetails = (props) =>{
  const {itemDetails} = props || {}
  const { notificationContent, notificationCreatedDate } = itemDetails?.notificationId || ''
  const {firstName, lastName} = itemDetails?.userProfileDTO || ''
  const userName= <span>{firstName}&nbsp;{lastName}</span>
  const notificationType = {
    ALERT: 'alert_reminder',
    CONTRACT: 'comtract_reminder',
    MASSCOMM: 'mass_comm',
    SERVICE: 'service_reminder',
  }

  return (
    notificationContent ? <div className='detail-main-div'>
        <div className='detail-header'>
          <img alt='notfication-icon' height={48} width={48} src={getIcon(notificationType.ALERT)} />
          <div className='name-div'>
            {userName}
            <div className='timestamp-span'>{moment(notificationCreatedDate).fromNow()}</div>
          </div>
        </div>
        <div className='detail-div'>
          <p>Hello {userName}&#44;</p>
          <span>{notificationContent}</span>
        </div>
    </div>:<span>{notificationContent}</span>
  )
}

NotificationDetails.propTypes = {
  itemDetails: PropTypes.object,
}

export default NotificationDetails
