import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import safeFetch from 'store/utils/safeFetch'
export const moduleId = 'newsCenter'

// Constants
export const constants = {
    FETCH: 'NewsCenter/FETCH',
    FETCH_FAILURE: 'NewsCenter/FETCH_FAILURE',
    RECEIVE_NEWS_CENTER_FILTERS: 'NewsCenter/RECEIVE_NEWS_CENTER_FILTERS',
    ADD_NEWS_FEED_START: 'NewsCenter/ADD_NEWS_FEED_START',
    ADD_NEWS_FEED_SUCCESS: 'NewsCenter/ADD_NEWS_FEED_SUCCESS',
    ADD_NEWS_FEED_FAIL: 'NewsCenter/ADD_NEWS_FEED_FAIL',
    EDIT_NEWS_FEED_START: 'NewsCenter/EDIT_NEWS_FEED_START',
    EDIT_NEWS_FEED_SUCCESS: 'NewsCenter/EDIT_NEWS_FEED_SUCCESS',
    EDIT_NEWS_FEED_FAIL: 'NewsCenter/EDIT_NEWS_FEED_FAIL',
    RESET_UPDATE_SUCCESS: 'NewsCenter/RESET_UPDATE_SUCCESS',
}


// Action Creators
export const actions = {
    getNewsCenterFilters(permission) {
        return safeFetch({
            onFetch: () => ({ type: constants.FETCH }),
            apiFunction: 'fetchHowToFilters',
            onSuccess: ({ data }) => ({ type: constants.RECEIVE_NEWS_CENTER_FILTERS, data, permission }),
            onFailure: (error) => ({ type: constants.FETCH_FAILURE, error: error.message })
        })
    },
    addNewsFeed(data) {
        return safeFetch({
            onFetch: () => ({ type: constants.ADD_NEWS_FEED_START }),
            throwError: true,
            apiFunction: 'addNewsFeed',
            args: data,
            onSuccess: ({ data, headers }) => ({ type: constants.ADD_NEWS_FEED_SUCCESS, data, headers }),
            onFailure: (error) => ({ type: constants.ADD_NEWS_FEED_FAIL, error: error }),
        })
    },
    editNewsFeed(data) {
        return safeFetch({
            onFetch: () => ({ type: constants.EDIT_NEWS_FEED_START }),
            throwError: true,
            apiFunction: 'editNewsFeed',
            args: data,
            onSuccess: ({ data, headers }) => ({ type: constants.EDIT_NEWS_FEED_SUCCESS, data, headers }),
            onFailure: (error) => ({ type: constants.EDIT_NEWS_FEED_FAIL, error: error }),
        })
    },
    resetUpdateState() {
        return { type: constants.RESET_UPDATE_SUCCESS }
    }
}

// Reducer
export const initialState = fromJS({
    fetching: false,
    fetchError: null,
    newsCenterFilters: [],
    newsFeedAdd: null,
    newsFeedEdit: null
})

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.FETCH:
            return state
                .set('fetching', true)
                .set('fetchError', null)
        case constants.FETCH_FAILURE:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
        case constants.RECEIVE_NEWS_CENTER_FILTERS:
            return state
                .set('newsCenterFilters', parseFilters(action?.data, action?.permission))
                .set('fetching', false)
        case constants.ADD_NEWS_FEED_START:
            return state.set('fetching', true)
        case constants.ADD_NEWS_FEED_SUCCESS:
            return state
                .set('newsFeedAdd', true)
                .set('fetching', false)
        case constants.ADD_NEWS_FEED_FAIL:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
                .set('newsFeedAdd', null)
        case constants.EDIT_NEWS_FEED_START:
            return state.set('fetching', true)
        case constants.EDIT_NEWS_FEED_SUCCESS:
            return state
                .set('newsFeedEdit', true)
                .set('fetching', false)
        case constants.EDIT_NEWS_FEED_FAIL:
            return state
                .set('fetchError', action.error)
                .set('fetching', false)
                .set('newsFeedEdit', null)
        case constants.RESET_UPDATE_SUCCESS:
            return state
                .set('newsFeedEdit', null)
                .set('newsFeedAdd', null)
        default:
            return state
    }
}

// Selectors
const getState = (state) => state.newsCenter

export const selectors = {
    fetching: createSelector(getState, (state) => state.get('fetching')),
    fetchError: createSelector(getState, (state) => state.get('fetchError')),
    newsCenterFilters: createSelector(getState, (state) => state.get('newsCenterFilters')),
    newsFeedAdd: createSelector(getState, (state) => state.get('newsFeedAdd')),
    newsFeedEdit: createSelector(getState, (state) => state.get('newsFeedEdit')),
}

// Helper functions
const parseFilters = ({ fieldOfInterests, deviceTypes }, permission) => {
    const filters = {
        fieldOfInterest: fieldOfInterests && fieldOfInterests.length && fieldOfInterests || [],
        deviceTypeId: deviceTypes && deviceTypes.length && deviceTypes || []
    }
    const searchFilters = ['videoTitle']
    const requiredFilters = ['fieldOfInterest', 'deviceTypeId']
    const updatedFilters = []
    requiredFilters.forEach((name) => {
        const filterConfig = {
            searchBox: searchFilters.includes(name),
            searchBoxHighlightSelection: true
        }

        updatedFilters.push({
            categoryType: name,
            disableSearchFilter: searchFilters.includes(name) && !filters[name]?.length,
            categoryValues: filters[name] ? filters[name]?.map((value) => {
                if (name === 'fieldOfInterest') {
                    return ({
                        name: value.fieldOfInterestName,
                        label: value.fieldOfInterestName,
                        value: value.fieldOfInterestName
                    })
                } else if (name === 'deviceTypeId') {
                    return ({
                        name: value.deviceTypeName,
                        label: value.deviceTypeName,
                        value: value.deviceTypeId
                    })
                }
            }) : [],
            ...filterConfig
        })
    })
    return updatedFilters
}