import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'
import { Table } from 'antDComponents'
import style from './TableList.scss'
import classNames from 'classnames/bind'
import Loader from 'components/Loader/Loader'
import {isEmptyArray} from 'utils/dataUtils'

const cx = classNames.bind(style)
const propTypes = {
  handleSort: PropTypes.func,
  headers: PropTypes.array,
  data: PropTypes.object,
  headersTitle: PropTypes.string,
  className: PropTypes.string,
  fetching: PropTypes.bool,
  totalPages: PropTypes.number,
  withPagination: PropTypes.bool,
  disabledRows: PropTypes.bool,
  getDisabledRow: PropTypes.array,
  sortHeaders: PropTypes.func,
  columnWidths: PropTypes.array
}
const defaultProps = {
  getKey: (item, i) => i,
  withPagination: false,
  disabledRows: false
}

const isSortable = (headers, handleSort, headersTitle) => headers?.map(({isSort, sorted, header,isSortable}) => {
  return {
    header: <FormattedMessage id={`next.${headersTitle}${header}`} />,
    accessor: (rows) => (rows && rows[header]),
    isSortable: isSortable != undefined ? isSortable : true,
    onSort: () => !isSort ? handleSort(header) : 'UNSORTABLE',
    sorted: sorted,
    responsive: ['sm']
  }
})
const TableList = (props) => {
  const {sortHeaders, headers, data, handleSort, headersTitle, fetching, className, withPagination, disabledRows, getDisabledRow, columnWidths} = props
  let content = <div />
  if (fetching) {
    content = <Loader size='sm' />
  } else if (isEmptyArray(data) || (data?.isEmpty && data?.isEmpty())) {
    content = <div className='table-no-results-content'>
      <FormattedMessage id='next.NO_RESULTS' />
    </div>
  } else {
    content = (
      <div className={cx('container-table', className)}>
        <div className={cx('wrap-table')}>
          <Table
            columns={isSortable(headers, handleSort, headersTitle)}
            rows={data && data.toJS()}
            isSortable
            withPagination={withPagination}
            paginationBelow
            paginationItemsPerPageOptions={[10, 25, 50]}
            size={'large'}
            disabledRowsIndexes={disabledRows ? getDisabledRow(data) : []}
            sortHeaders={sortHeaders}
            headers= {headers}
            columnWidths={columnWidths}
          />
        </div>
      </div>)
  }
  return (
    <div>
      {content}
    </div>
  )
}
TableList.propTypes = propTypes
TableList.defaultProps = defaultProps

export default TableList
