import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import './SubmitCancelFormButton.scss'

const submitCancelFormButton = ({submitMessage, cancelMessage, handleSubmit, isSubmitting, close}) =>(
  <div className='button-bar'>
    <Button size='sm' type='muted' onClick={close}>
      <FormattedMessage id={cancelMessage} />
    </Button>

    <Button
      size='sm'
      type='primary'
      onClick={handleSubmit}
      disabled={isSubmitting}
    >
      <FormattedMessage
        id={submitMessage}
      />
    </Button>
  </div>
)
submitCancelFormButton.propTypes = {
  submitMessage: PropTypes.string,
  cancelMessage: PropTypes.string,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  close: PropTypes.func
}
export default submitCancelFormButton