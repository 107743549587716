import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import safeFetch from 'store/utils/safeFetch'
import { selectors as pageSelectors } from 'store/modules/Pagination'


export const moduleId = 'userNotification'

export const constants = {
  FETCH_USER_NOTIFICATION: 'NOTIFICATION/FETCH',
  USER_NOTIFICATION_COUNT_SUCCESS: 'NOTIFICATION/USER_NOTIFICATION_COUNT_SUCCESS',
  USER_NOTIFICATION_COUNT_FAILURE: 'NOTIFICATION/USER_NOTIFICATION_COUNT_FAILURE',
  FETCH_USER_NOTIFICATION_LIST_SUCCESS: 'NOTIFICATION/FETCH_USER_NOTIFICATION_LIST_SUCCESS',
  FETCH_USER_NOTIFICATION_LIST_FAILURE: 'NOTIFICATION/FETCH_USER_NOTIFICATION_LIST_FAILURE',
  SET_CURRENT_PAGE: 'NOTIFICATION/SET_CURRENT_PAGE',
  UPDATE_USER_NOTIFICATION_SUCCESS: 'NOTIFICATION/UPDATE_USER_NOTIFICATION',
  UPDATE_USER_NOTIFICATION_FAILURE: 'NOTIFICATION/UPDATE_USER_FAILURE',
  UPDATE_USER_NOTIFICATION_SEARCH: 'NOTIFICATION/UPDATE_USER_NOTIFICATION_SEARCH',
  USER_NOTIFICATION_HISTORY_OPENED: 'NOTIFICATION/USER_NOTIFICATION_HISTORY_OPENED',
}

// Action Creators
export const actions = {

  getNotificationFetchQuery(getState) {
    const orderBy = 'asc'
    const page = pageSelectors.pagination(getState()).getIn(['userNotification', 'currentPage'])
    const pageLimit = pageSelectors.perPage(getState())
    const searchBy = selectors.notfcnSearchValue(getState())
    const query = {
      orderBy,
      page,
      pageLimit,
      searchBy
    }
    return query
  },

  setCurrentPage (page) {
    return { type: constants.SET_CURRENT_PAGE, page }
  },

  getUserNotificationCount() {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_USER_NOTIFICATION}),
      apiFunction: 'getUserNotificationCount',
      onSuccess: (data) => ({ type: constants.USER_NOTIFICATION_COUNT_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.USER_NOTIFICATION_COUNT_FAILURE, error: error.toString() })
    })
  },

  getUserNotificationList() {
    return safeFetch({
      onFetch: () => ({type:  constants.FETCH_USER_NOTIFICATION}),
      preventMultipleRequest: true,
      apiFunction: 'getUserNotifcationList',
      args: actions.getNotificationFetchQuery,
      onSuccess: ({ data , headers}) => ({type: constants.FETCH_USER_NOTIFICATION_LIST_SUCCESS, data, headers}),
      onFailure: (error) => ({type: constants.FETCH_USER_NOTIFICATION_LIST_FAILURE, error: error.message})
    })
  },

  updateUserNotifcation(id){
    return safeFetch({
      onFetch: () => ({type:  constants.FETCH_USER_NOTIFICATION}),
      preventMultipleRequest: true,
      apiFunction: 'updateUserNotifcation',
      args: id,
      onSuccess: ({ data , headers}) => ({type: constants.UPDATE_USER_NOTIFICATION_SUCCESS, data}),
      onFailure: (error) => ({type: constants.UPDATE_USER_NOTIFICATION_FAILURE, error: error.message})
    })
  },

  updateSearchValue(value){
    return { type: constants.UPDATE_USER_NOTIFICATION_SEARCH, value }
  },

  updateUserNotifcnOpened(value){
    return { type: constants.USER_NOTIFICATION_HISTORY_OPENED, value }
  }
}

// Reducer
export const initialState = fromJS({
  fetching: false,
  fetchError: null,
  notificationCount: 0,
  totalCount: 0,
  currentPage: 0,
  searchValue: '',
  userNotifcationOpened: false
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_USER_NOTIFICATION:
      return state
        .set('fetching', true)
        .set('fetchError', null)
    case constants.USER_NOTIFICATION_COUNT_SUCCESS:
      return state
        .set('notificationCount', action?.data?.data)
        .set('fetching', false)
    case constants.USER_NOTIFICATION_COUNT_FAILURE:
      return state
        .set('fetchError', action.error)
        .set('fetching', false)
    case constants.FETCH_USER_NOTIFICATION_LIST_SUCCESS:
      return state
      .set('listData', fromJS(action.data))
      .set('fetching', false)
      .set('totalCount', parseInt(fromJS(action?.headers?.get('x-record-count'))))
    case constants.FETCH_USER_NOTIFICATION_LIST_FAILURE:
    return state
      .set('fetchError', action.error)
      .set('fetching', false)
    case constants.UPDATE_USER_NOTIFICATION_SUCCESS:
      return state
      .set('fetching', false)
    case constants.UPDATE_USER_NOTIFICATION_FAILURE:
      return state
      .set('fetchError', action.error)
      .set('fetching', false)
      case constants.UPDATE_USER_NOTIFICATION_SEARCH:
        return state
        .set('notfcnSearchValue', action.value)
      case constants.USER_NOTIFICATION_HISTORY_OPENED:
        return state
        .set('userNotifcationOpened', action.value)
    default:
      return state
  }
}

// Selectors
const getState = (state) => state.userNotification

export const selectors = {
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  notificationCount: createSelector(getState, (state) => {
    return state?.get('notificationCount')
  }),
  listData: createSelector(getState, (state) =>{
    return state?.get('listData')
  }),
  totalCount: createSelector(getState, (state) =>
    state.get('totalCount')
  ),
  currentPage: createSelector(getState, (state) =>
    state.get('currentPage')
  ),
  notfcnSearchValue: createSelector(getState, (state) =>
    state.get('notfcnSearchValue')
  ),
  userNotifcationOpened: createSelector(getState,(state) =>
    state.get('userNotifcationOpened')
  )
}
