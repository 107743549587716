export const mainRoutes = {
    MAIN_MENU_HOME_BUTTON: '/dashboard',
    GLOBAL: '/global',
    MAIN_MENU_DEVICE_LIST: '/devices',
    MAIN_MENU_USERS: '/users',
    USER_PROFILE: '/profile',
    MAIN_MENU_DEVICE_REGISTRY_BUTTON: '/devices/registry',
    CARDS: '/cards',
    EDIT_CARD: '/cards/edit',
    CARDS_CREATION: '/cards/create',
    ORGANIZATION_DETAILS: '/organizations',
    ADD_ROLE: '/roles/add',
    DEVICE_ACCESS_ACTION: '/users/deviceaccessaction',
    CONTACT_HOTLINE_DASHBOARD: '/managecontacthotline',
    DEVICES_SOFTWARE_UPDATES: '/devicesoftware/update',
    DEVICE_SOFTWARE_UPLOAD: '/devicesoftware/upload',
    DEVICE_SOFTWARE_VALIDATE: '/devicesoftware/validate',
    GLOBAL_FILE_MANAGEMENT: '/files',
    CERTIFICATES_STATUS: '/certificatesstatus',
    TERMS_CONDITION: '/terms',
    PRIVACY_POLICY: '/policy',
    MANAGE_NEWS_FEED: '/newsfeed',
    CUSTOMER_DEVICES: '/users ',
    USER_EXPERIENCE_FEEDBACK_IMPROVEMENT: '/userexperience',
    FEEDBACK_DEFAULTERS: '/feedbackdefaulters',
    SERVICE_CONTRACT_MANAGEMENT: '/servicecontract',
    DATA_ANALYSIS: '/dataanalysis',
    HOW_TO: '/howto',
    NOTIFICATION_HISTORY: '/notificationHistory',
    NEWS_CENTER: '/newscenter'
  }

export default mainRoutes
