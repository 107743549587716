import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import './UserNotification.scss'
import Loader from 'components/Loader'
import NotificationList from './NotificationList'
import { PATHS } from 'routes'
import { FormattedMessage } from 'react-intl'

const UserNotification = ({totalCount,getUserNotifcationList, listData, fetching, setFetching, handleListNotification, ...restProps}) =>{
  const [notfcnData, setNotfcnData] = useState([])
  const [showDetails, setShowDetails] = useState(false)
  const MAX_COUNT = 10
  const history = useHistory()
  useEffect(() =>{
    setFetching(true)
    getUserNotifcationList()
   },[])

   useEffect(() =>{
    if(listData){
      const listing = listData?.toJS()
      setNotfcnData(listing)
      setFetching(false)
    }
   },[listData])

   const handleNotification = async (id) =>{
    history.push(PATHS.NOTIFICATION_HISTORY)
    handleListNotification(id)
    await restProps?.handleNotification()
   }

  return (
    <React.Fragment>
     {fetching && <Loader customClass='device-loader-container' />}
      {notfcnData?.length > 0 ? <NotificationList
                handleNotification = {handleNotification}
                listData={listData}
                getUserNotifcationList={getUserNotifcationList}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />:
              (<div style={{height: '30px', textAlign: 'center'}}><FormattedMessage id='next.NO_DATA' /></div>)
      }
         {totalCount > MAX_COUNT && <div className='viewallnotfcn-div' onClick={handleNotification}><span>View all notification</span></div>}

    </React.Fragment>


  )
}

UserNotification.propTypes = {
  handlePerpageLimit: PropTypes.func,
  handlePagination: PropTypes.func,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  getUserNotifcationList: PropTypes.func,
  listData: PropTypes.object,
  fetching: PropTypes.bool,
  setFetching: PropTypes.func,
  handleListNotification: PropTypes.func
}

export default UserNotification
