import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import { deviceMaxWidth } from 'utils/screenSizeConstants'
import mainRoutes from '../../constants/mainroutes'
import safeFetch from 'store/utils/safeFetch'

// import cookie from 'react-cookie'
// import { cookiePolicyAcceptance } from 'constants/profile'
// import { hashingFunction } from 'utils/userInfoUtils'

// Constants
export const constants = {
  TOGGLE_SEARCH: 'HEADER/TOGGLE_SEARCH',
  TOGGLE_STICK_HEADER: 'HEADER/TOGGLE_STICK_HEADER',
  SET_BREADCRUMB: 'HEADER/SET_BREADCRUMB',
  SET_PROFILE_POPUP: 'HEADER/SET_PROFILE_POPUP',
  REVOKE_COOKIE_POLICY: 'HEADER/REVOKE_COOKIE_POLICY',
  ACCEPT_COOKIE_POLICY: 'HEADER/ACCEPT_COOKIE_POLICY',
  FETCH_COOKIE_POLICY_ACCEPTANCE: 'HEADER/FETCH_COOKIE_POLICY_ACCEPTANCE',
  FETCH: 'HEADER/FETCH',
  SUBMIT_FEEDBACK_START: 'HEADER/SUBMIT_FEEDBACK_START',
  SUBMIT_FEEDBACK_SUCCESS: 'HEADER/SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_FAILURE: 'HEADER/SUBMIT_FEEDBACK_FAILURE',
  RESET_SUCCESS_STATUS: 'HEADER/RESET_SUCCESS_STATUS',
  FETCH_ALL_USER_FEEDBACK_START: 'HEADER/FETCH_ALL_USER_FEEDBACK_START',
  FETCH_ALL_USER_FEEDBACK_FAILURE: 'HEADER/FETCH_ALL_USER_FEEDBACK_FAILURE',
  FETCH_ALL_USER_FEEDBACK_SUCCESS: 'HEADER/FETCH_ALL_USER_FEEDBACK_SUCCESS',
  SIDEBAR_COLLAPSE_EXPAND: 'HEADER/SIDEBAR_COLLAPSE_EXPAND',
  FETCH_SAVE_VERTICAL_MENU: 'HEADER/FETCH_SAVE_VERTICAL_MENU',
  SAVE_VERTICAL_MENU_SUCCESS: 'HEADER/SAVE_VERTICAL_MENU_SUCCESS',
  SAVE_VERTICAL_MENU_FAILURE: 'HEADER/SAVE_VERTICAL_MENU_FAILURE',
  RESET_VERTICAL_MENUS: 'HEADER/RESET_VERTICAL_MENUS',
  FETCH_VERTICAL_MENU: 'HEADER/FETCH_VERTICAL_MENU',
  FETCHED_VERTICAL_MENU_SUCCESS: 'HEADER/FETCHED_VERTICAL_MENU_SUCCESS',
  FETCHED_VERTICAL_MENU_FAILURE: 'HEADER/FETCHED_VERTICAL_MENU_FAILURE',
  FETCH_WELCOME_VIDEO_START: 'HEADER/FETCH_WELCOME_VIDEO_START',
  FETCH_WELCOME_VIDEO_SUCCESS: 'HEADER/FETCH_WELCOME_VIDEO_SUCCESS',
  FETCH_WELCOME_VIDEO_FAILURE: 'HEADER/FETCH_WELCOME_VIDEO_FAILURE'
}

// Action Creators
export const actions = {
  postUserFeedback(data) {
    return safeFetch({
      args: data,
      throwError: true,
      apiFunction: 'postUserFeedback',
      onFetch: () => ({ type: constants.SUBMIT_FEEDBACK_START }),
      onSuccess: ({ data }) => ({ type: constants.SUBMIT_FEEDBACK_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.SUBMIT_FEEDBACK_FAILURE, error })
    })
  },
  getAllUserFeedback() {
    return safeFetch({
      throwError: true,
      apiFunction: 'getAllUserFeedback',
      onFetch: () => ({ type: constants.FETCH_ALL_USER_FEEDBACK_START }),
      onSuccess: ({ data }) => ({ type: constants.FETCH_ALL_USER_FEEDBACK_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_ALL_USER_FEEDBACK_FAILURE, error })
    })
  },
  getWelcomeVideo() {
    return safeFetch({
      throwError: true,
      apiFunction: 'welcomeVideo',
      onFetch: () => ({ type: constants.FETCH_WELCOME_VIDEO_START }),
      onSuccess: ({ data, headers }) => {
        window.localStorage.removeItem('isVideo')
        return { type: constants.FETCH_WELCOME_VIDEO_SUCCESS, data, headers }
      },
      onFailure: (error) => ({ type: constants.FETCH_WELCOME_VIDEO_FAILURE, error })
    })
  },
  getUserFeedback(userId) {
    return safeFetch({
      args: userId,
      throwError: true,
      apiFunction: 'getUserFeedback',
      onFetch: () => ({ type: constants.FETCH_ALL_USER_FEEDBACK_START }),
      onSuccess: ({ data }) => ({ type: constants.FETCH_ALL_USER_FEEDBACK_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_ALL_USER_FEEDBACK_FAILURE, error })
    })
  },
  resetSuccessStatus() {
    return { type: constants.RESET_SUCCESS_STATUS }
  },
  toggleSearch() {
    return { type: constants.TOGGLE_SEARCH }
  },
  toggleStickHeader() {
    return { type: constants.TOGGLE_STICK_HEADER }
  },
  setBreadcrumb(routeName = '', location = '') {
    return { type: constants.SET_BREADCRUMB, routeName, location }
  },
  openProfileHeader() {
    return { type: constants.SET_PROFILE_POPUP }
  },
  revokeCookiePolicy() {
    return { type: constants.REVOKE_COOKIE_POLICY }
  },
  handleSidebarToggle(sidebarToggleIsActive) {
    return { type: constants.SIDEBAR_COLLAPSE_EXPAND, sidebarToggleIsActive }
  },
  // acceptCookiePolicy(userInfo) {
  //   let hashValue = hashingFunction(userInfo.username)
  //   // expiry date is one month later the user accept the cookie
  //   cookie.save(hashValue, true, {
  //     path: '/',
  //     maxAge: 2592000,
  //     httpOnly: false,
  //     secure: true
  //   })

  //   return { type: constants.ACCEPT_COOKIE_POLICY }
  // },
  // fetchCookiePolicyAcceptance() {
  //   const acceptance = cookie.load(cookiePolicyAcceptance)

  //   return {
  //     type: constants.FETCH_COOKIE_POLICY_ACCEPTANCE,
  //     acceptance
  //   }
  // }

  saveVerticalMenu(data) {
    return async (dispatch) => {
      await dispatch(safeFetch({
        onFetch: () => ({ type: constants.FETCH_SAVE_VERTICAL_MENU }),
        apiFunction: 'saveDeviceDetailsBtn',
        args: data,
        onSuccess: ({ data }) => {
          dispatch(actions.fetchVerticalMenu('HOMEPAGE_VERTICALBUTTONS'))
          return ({
            type: constants.SAVE_VERTICAL_MENU_SUCCESS,
            data
          })
        },
        onFailure: ({ status }) => ({
          type: constants.SAVE_VERTICAL_MENU_FAILURE,
          error: status
        })
      })
      )
    }
  },
  resetVerticalMenu() {
    return { type: constants.RESET_VERTICAL_MENUS }
  },
  fetchVerticalMenu(menuGroupName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_VERTICAL_MENU }),
      apiFunction: 'fetchDeviceDetailsBtn',
      args: menuGroupName,
      onSuccess: ({ data }) => ({ type: constants.FETCHED_VERTICAL_MENU_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCHED_VERTICAL_MENU_FAILURE, error }),
      throwError: true
    })
  },

  getUserNotification(userId) {
    return safeFetch({
      args: userId,
      throwError: true,
      apiFunction: 'getUserNotification',
      onFetch: () => ({ type: constants.FETCH_USER_NOTIFICATION_START }),
      onSuccess: ({ data }) => ({ type: constants.FETCH_USER_NOTIFICATION_SUCCESS, data }),
      onFailure: (error) => ({ type: constants.FETCH_USER_NOTIFICATION_FAILURE, error })
    })
  }
}

// Reducer
export const initialState = fromJS({
  showSearch: false,
  openConsentDialogue: false,
  openWelcomeVideoDialogue: null,
  openWelcomeVideoContentType: null,
  stickHeader: false,
  routeName: [],
  mbleprofilePopup: false,
  cookiePolicyAcceptance: false,
  isSubmit: false,
  isError: null,
  isFeedbackSuccess: false,
  isUserFbdkFetching: true,
  userRating: null,
  isRated: false,
  sidebarToggleIsActive: window.innerWidth <= deviceMaxWidth.IPAD ? true : false,
  fetchedVerticalMenuList: false,
})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.TOGGLE_SEARCH:
      return state
        .set('showSearch', !state.get('showSearch'))
    case constants.TOGGLE_STICK_HEADER:
      return state
        .set('stickHeader', !state.get('stickHeader'))
    case constants.SET_BREADCRUMB:
      let routeName = state.get('routeName') && state.get('routeName').size && state.get('routeName') || JSON.parse(sessionStorage.getItem('routeName'))
      var oldBreadCrumbItems = Object.assign([], routeName)
      if (action.routeName === '')
        return state
          .set('routeName', [])
      let breadcrumbName = action.routeName
      let obj = { 'routeName': action.routeName, 'location': action.location }
      let home = { 'routeName': 'MAIN_MENU_HOME_BUTTON', 'location': '/dashboard' }
      let index = oldBreadCrumbItems.findIndex(item => item.routeName === breadcrumbName)
      if (mainRoutes[breadcrumbName]) {
        oldBreadCrumbItems = []
        if (obj.routeName !== home.routeName) oldBreadCrumbItems.push(home)
        oldBreadCrumbItems.push(obj)
        sessionStorage.setItem('routeName', JSON.stringify(oldBreadCrumbItems))
        return state
          .set('routeName', oldBreadCrumbItems)
      } else if (index >= 0) {
        oldBreadCrumbItems.splice(index + 1, oldBreadCrumbItems.length - index - 1)
        sessionStorage.setItem('routeName', JSON.stringify(oldBreadCrumbItems))
        return state
          .set('routeName', oldBreadCrumbItems)
      } else {
        oldBreadCrumbItems.push(obj)
        sessionStorage.setItem('routeName', JSON.stringify(oldBreadCrumbItems))
        return state
          .set('routeName', oldBreadCrumbItems)
      }
    case constants.SET_PROFILE_POPUP:
      return state
        .set('mbleprofilePopup', !state.get('mbleprofilePopup'))
    case constants.FETCH_COOKIE_POLICY_ACCEPTANCE:
      return state
        .set('cookiePolicyAcceptance', action.acceptance)
    case constants.ACCEPT_COOKIE_POLICY:
      return state
        .set('cookiePolicyAcceptance', true)
    case constants.REVOKE_COOKIE_POLICY:
      return state
        .set('cookiePolicyAcceptance', false)
    case constants.SUBMIT_FEEDBACK_SUCCESS:
      return state
        .set('isSubmit', false)
        .set('isFeedbackSuccess', true)
    case constants.SUBMIT_FEEDBACK_FAILURE:
      return state
        .set('isSubmit', false)
        .set('isError', action.data)
    case constants.RESET_SUCCESS_STATUS:
      return state
        .set('isFeedbackSuccess', false)
        .set('isError', null)
    case constants.FETCH_ALL_USER_FEEDBACK_START:
      return state
        .set('isUserFbdkFetching', true)
        .set('isError', null)
    case constants.FETCH_ALL_USER_FEEDBACK_SUCCESS:
      return state
        .set('isUserFbdkFetching', false)
        .set('userRating', action.data)
        .set('openConsentDialogue', action.data.status)
        .set('isRated', true)
    case constants.FETCH_ALL_USER_FEEDBACK_FAILURE:
      return state
        .set('isUserFbdkFetching', false)
        .set('isRated', false)
    case constants.FETCH_WELCOME_VIDEO_START:
      return state
        .set('isSubmit', true)
        .set('isError', null)
    case constants.FETCH_WELCOME_VIDEO_SUCCESS:
      return state
      .set('openWelcomeVideoDialogue', action.data)
      .set('openWelcomeVideoContentType', action.headers.get('content-type'))
      .set('isSubmit', false)
    case constants.FETCH_WELCOME_VIDEO_FAILURE:
      return state
        .set('isSubmit', false)
        .set('isError', action.error)
    case constants.SIDEBAR_COLLAPSE_EXPAND:
      return state
        .set('sidebarToggleIsActive', action.sidebarToggleIsActive)
    case constants.RESET_VERTICAL_MENUS:
      return state
        .set('fetchingSaveVerticalMenu', false)
        .set('ackSaveVerticalMenu', null)
        .set('errorSaveVerticalMenu', null)
    case constants.FETCH_SAVE_VERTICAL_MENU:
      return state
        .set('fetchingSaveVerticalMenu', true)
    case constants.SAVE_VERTICAL_MENU_SUCCESS:
      return state
        .set('fetchingSaveVerticalMenu', false)
        .set('ackSaveVerticalMenu', action.data)
    case constants.SAVE_VERTICAL_MENU_FAILURE:
      return state
        .set('fetchingSaveVerticalMenu', false)
        .set('errorSaveVerticalMenu', action.error)
    case constants.FETCH_VERTICAL_MENU:
      return state
        .set('fetchingVerticalMenuList', true)
        .set('ackSaveVerticalMenu', null)
    case constants.FETCHED_VERTICAL_MENU_SUCCESS:
      return state
        .set('verticalMenuList', fromJS(action.data))
        .set('fetchingVerticalMenuList', false)
        .set('fetchedVerticalMenuList', true)
    case constants.FETCHED_VERTICAL_MENU_FAILURE:
      return state
        .set('verticalMenuListError', action.error)
        .set('fetchingVerticalMenuList', false)
        .set('fetchedVerticalMenuList', false)

    default:
      return state
  }
}

// Selectors
const getState = (state) => state.header

export const selectors = {
  showSearch: createSelector(getState, (state) => {
    return state.get('showSearch')
  }),
  stickHeader: createSelector(getState, (state) => {
    return state.get('stickHeader')
  }),
  getRouteName: createSelector(getState, (state) => {
    return state.get('routeName')
  }),
  mbleprofilePopup: createSelector(getState, (state) => {
    return state.get('mbleprofilePopup')
  }),
  cookiePolicyAcceptance: createSelector(getState, (state) => {
    return state.get('cookiePolicyAcceptance')
  }),
  isSubmit: createSelector(getState, (state) => {
    return state.get('isSubmit')
  }),
  isError: createSelector(getState, (state) => {
    return state.get('isError')
  }),
  isFeedbackSuccess: createSelector(getState, (state) => {
    return state.get('isFeedbackSuccess')
  }),
  isUserFbdkFetching: createSelector(getState, (state) => {
    return state.get('isUserFbdkFetching')
  }),
  userRating: createSelector(getState, (state) => {
    return state.get('userRating')
  }),
  isRated: createSelector(getState, (state) => {
    return state.get('isRated')
  }),
  sidebarToggleIsActive: createSelector(getState, (state) => {
    return state.get('sidebarToggleIsActive')
  }),
  ackSaveVerticalMenu: createSelector(getState, (state) => {
    return state.get('ackSaveVerticalMenu')
  }),
  fetchingVerticalMenuList: createSelector(getState, (state) => {
    return state.get('fetchingVerticalMenuList')
  }),
  verticalMenuListError: createSelector(getState, (state) => {
    return state.get('verticalMenuListError')
  }),
  verticalMenuList: createSelector(getState, (state) => {
    return state.get('verticalMenuList')
  }),
  fetchingSaveVerticalMenu: createSelector(getState, (state) => {
    return state.get('fetchingSaveVerticalMenu')
  }),
  openConsentDialogue: createSelector(getState, (state) => {
    return state.get('openConsentDialogue')
  }),
  openWelcomeVideoDialogue: createSelector(getState, (state) => {
    return state.get('openWelcomeVideoDialogue')
  }),
  openWelcomeVideoContentType: createSelector(getState, (state) => {
    return state.get('openWelcomeVideoContentType')
  }),
  fetchedVerticalMenuList: createSelector(getState, (state) => {
    return state.get('fetchedVerticalMenuList')
  })
}
