import Request from 'api/Request'
import { ensureIntl } from 'utils/loadIntl'
import { constants as tokenConstants } from 'utils/authUtils'
import { NextExpectedListingData, NextExpectedFilters } from './GenericListingData'
const userhubRequest = new Request('userhub', 'api')
const userhubRequestV1 = new Request('userhub', 'api', 'v1')
const devicehubRequest = new Request('devicehub', 'api')
const devicehubRequestV1 = new Request('devicehub', 'api', 'v1')
const devicehubRequestV2 = new Request('devicehub', 'api', 'v2')
const analyticsRequest = new Request('analytics', 'api')
const filemanagementRequest = new Request('filemanagement')
const messageRequest = new Request('translations')
const appVersionRequest = new Request()
const partnerDashboardRequest = new Request('devicehub', 'api')
const dataAnalysisRequest = new Request('kpi', 'api', 'v1')
const howToRequest = new Request('how-to', 'api', 'v1', 'video')
const newsCenter = new Request('notification', 'api', 'notificationcenter')
const userNotification = new Request('notification', 'api', 'notification')

export const filterCategories = async () => (
  await devicehubRequestV2.getRequest('devices', 'events', 'filters')()
)
export const globalListing = async (query) => (
  await devicehubRequestV2.getRequest('devices', 'events')(query)
)
export const nextExpectedFiltersData = () => NextExpectedFilters
export const nextExpectedListingData = (query) => NextExpectedListingData

// api Calls
// messages (production)
export const getMessages = async ({ locale, ...query }) => await messageRequest.getRequest('nc', `${locale}.json`)(query, null)
// messages (development)
export const getMessagesDevelopment = async ({ locale }) => await ensureIntl(locale)

// users
// export const fetchUsers = async (query) => userListData
export const fetchUsers = async (query) => await userhubRequest.getRequest('users')(query)

export const invitingUser = async (user) => await userhubRequest.postRequest('users')(user)
export const updateUserDetails = async ({ user, userId }) => await userhubRequest.putRequest('users', userId)(user)

export const removeUser = async (userId) => await userhubRequest.deleteRequest('users', userId)()
export const changeStatus = async ({ userId, status }) => await userhubRequest.patchRequest('users', userId)(status)
export const fetchUserProfileData = async (userId) => await userhubRequest.getRequest('users', userId)()
export const updateUserRole = async ({ userId, role }) => await userhubRequest.patchRequest('users', userId)(role)
export const updateUserProfile = async ({ userId, query }) => {
  return await userhubRequest.patchRequest('users', userId)(query)
}
export const forgotPassword = async (email) => await userhubRequest.putRequest('users', 'passwordreset')(email)
export const reInviteUser = async ({ userId }) => await userhubRequest.putRequest('users', userId, 'invite')()
export const fetchPerPageItemList = async () => await userhubRequest.getRequest('outputDisplay')()
export const fetchUserPrivileges = async ({ userId }) => {
  return await userhubRequest.getRequest('users', userId, 'privileges')()
}
export const addUserPrivileges = async ({ userId, privileges }) => {
  return await userhubRequest.postRequest('users', userId, 'privileges')(privileges)
}
export const removeUserPrivileges = async ({ userId, privileges }) => {
  return await userhubRequest.deleteRequest('users', userId, 'privileges')(privileges)
}
export const unsubscribeUser = async () => {
  return await userhubRequestV1.deleteRequest('user-unsubscribe')()
}
export const fetchUserTerritories = async ({ userId, ...query }) => {
  return await userhubRequest.getRequest('users', userId, 'privileges')(query)
}

export const fetchPartnerAndBrandType = async (query) => await userhubRequest.getRequest('v1', 'orgs', 'brandPartnerTypes')(query)
export const getUserImage = async (userId) => await userhubRequest.getRequest('users', userId, 'image')()
export const addUserImage = async ({ userId, updatedImage }) => await devicehubRequest.postRequestFile('devices', userId, 'image')({}, updatedImage)
export const deleteUserImage = async (userId) => await userhubRequest.deleteRequest('users', userId, 'image')()

// fetch users based on org
export const fetchOrgUsers = async ({ query: { filterBy, ...query }, orgId }) => {
  return await userhubRequest.getRequest('users')({ filterBy: [...filterBy, `orgId.${orgId}`], ...query })
}

// user Notification Preferences
export const validatePhoneNumber = async (query) => {
  return await userhubRequest.postRequest('v1', 'subscription', 'otp')(query)
}
export const getPhoneNumberValidationOTP = async (params) => {
  return await userhubRequest.getRequest('v1', 'subscription', 'otp')(params)
}
export const fetchSubscriptionCategories = async () => {
  return await userhubRequest.getRequest('v1', 'subscription')()
}
export const fetchUserSubscriptions = async (userId) => {
  return await userhubRequest.getRequest('v1', 'subscription', userId)()
}
export const postUserSubscriptions = async ({ userId, subscriptionData }) => {
  return await userhubRequest.postRequest('v1', 'subscription', userId)(subscriptionData)
}
export const updateUserSubscriptions = async ({ userId, subscriptionData }) => {
  return await userhubRequest.putRequest('v1', 'subscription', userId)(subscriptionData)
}
export const fetchUserEmails = async (mailId) => {
  return await userhubRequest.getRequest('v1', 'subscription', mailId, 'search')()
}
export const postUserEmails = async (emailData) => {
  return await userhubRequest.postRequest('v1', 'subscription')(emailData)
}

// dateFormats
export const fetchDateTimeFormats = async () => await userhubRequest.getRequest('dateformats')()

// roles
export const fetchRoles = async (query) => await userhubRequest.getRequest('roles')(query)
export const addRoles = async (role) => await userhubRequest.postRequest('roles')(role)
export const RemoveRole = async (roleId) => await userhubRequest.deleteRequest('roles', roleId)()
export const EditRoleName = async ({ name, roleId }) => await userhubRequest.putRequest('roles', roleId)(name)

// user facets
export const loadUserFacets = async () => await userhubRequest.getRequest('users', 'facets')()

// country
export const loadCountries = async (query) => await userhubRequest.getRequest('countries')(query)

// country
export const loadCountriesZone = async (query) => await userhubRequest.getRequest('countries', 'timezone')(query)

// languages
export const fetchAllLanguages = async () => await userhubRequest.getRequest('languages')()
// fetch language on the basis of filemanagement
export const docRelatedLanguages = async () => await filemanagementRequest.getRequest('v2', 'documents', 'languages')()
// domain
export const fetchDomains = async (args) => await userhubRequest.getRequest('v1', 'orgs', args.orgId, 'domains')()

//  organization types
export const fetchOrgTypes = async (query) => await userhubRequest.getRequest('v1', 'orgs', 'orgs-facets')(query)

// orginization
export const loadOrganization = async (query) => await userhubRequest.getRequest('v1', 'orgs',)(query)
/* export const fetchOrganizationDetails = async ({ searchBy }) => {
  const query = { searchBy }
  return await userhubRequestV1.getRequest('orgs')(query)
} */
export const fetchOrganizationDetails = async (query) => {
  return await userhubRequestV1.getRequest('orgs', 'list')(query)
}
export const addOrganizationDetails = async (query) => {
  return await userhubRequestV1.postRequest('orgs')(query)
}
export const editOrganizationDetails = async ({ orgId, ...query }) => {
  return await userhubRequestV1.putRequest('orgs', orgId)(query)
}
export const deleteOrganization = async (orgId) => {
  return await userhubRequestV1.deleteRequest('orgs', orgId)()
}

// privileges
export const getEntities = async () => await userhubRequest.getRequest('entities')()
export const getPrivileges = async (query) => await userhubRequest.getRequest('privileges')(query)
export const getRolePrivileges = async (args) => await userhubRequest.getRequest('roles', args.roleId, 'privileges')(args.query)
export const getRoleIdPrivileges = async (args) => await userhubRequest.getRequest('roles', 'manage', args.roleId, 'privileges')(args.query)
export const addRolePrivileges = async (args) => await userhubRequest.postRequest('roles', args.roleId, 'privileges')(args.privilegesList)
export const deleteRolePrivileges = async (args) => {
  return await userhubRequest.deleteRequest('roles', args.roleId, 'privileges')(args.privilegesList)
}
// export const getDeviceTypes = async () => deviceFilter
// export const getDeviceTypes = async () => await devicehubRequest.getRequest('device', 'registry', 'devicetypes')()
// export const fetchLocations = async (query) => await devicehubRequest.getRequest('device', 'locations')(query)
// export const fetchDeviceGroups = async (args) => await devicehubRequest.getRequest('device', 'locations', args.locationId, 'customers')(args.query)
// export const getDeviceGroupPrivileges = async (args) => await userhubRequest.getRequest('roles', args.roleId, 'devicegroups')(args.deviceTypeId)
export const getDeviceTypes = async () => await devicehubRequest.getRequest('device', 'registry', 'devicetypes')()
export const getAllDeviceTypes = async () => await devicehubRequest.getRequest('device', 'registry', 'all', 'devicetypes')()
export const fetchLocations = async (query) => await devicehubRequest.getRequest('device', 'locations')(query)
export const fetchDeviceGroups = async (args) => await devicehubRequest.getRequest('device', 'locations', args.locationId, 'customers')(args.query)
export const getDeviceGroupPrivileges = async (args) => ({ data: { 'privilegeId': 28, 'locations': null } })
// export const getDeviceGroupPrivileges = async (args) => await userhubRequest.getRequest('roles', args.roleId, 'devicegroups')(args.deviceTypeId)
export const addDeviceGroupPrivileges = async (args) => await userhubRequest.postRequest('roles', args.roleId, 'devicegroups')(args.privilegeList)
export const deleteDeviceGroupPrivileges = async (args) => await userhubRequest.deleteRequest('roles', args.roleId, 'devicegroups')(args.privilegeList)
// auth
export const ssoAuth = async (user) => await userhubRequest.postRequest('sso', 'token')(user, null)
export const userLogin = async (user) => await userhubRequest.postRequest('login')(user, null)
export const getAccessToken = async () => await userhubRequest.getRequest('token')({}, tokenConstants.REFRESH_TOKEN)
export const logout = async () => await userhubRequest.getRequest('logout')()
export const fetchUser = async () => await userhubRequest.getRequest('userinfo')()

// reset password
export const setPassword = async ({ resetPasswordData }) => await userhubRequest.postRequest('users', 'password')(resetPasswordData, tokenConstants.RESET_PASSWORD_TOKEN)
export const resetPassword = async ({ resetPasswordData, profileId }) => await userhubRequest.putRequest('users', profileId, 'password')(resetPasswordData)
export const resetExpiryCheck = async ({ token }) => await userhubRequest.getRequest('token', token)()

// change password
export const changePassword = async (changePasswordData) => await userhubRequest.postRequest('users', 'password')(changePasswordData)

// device registry
export const fetchDeviceRegistryData = async (query) => await devicehubRequest.getRequest('device', 'registry')(query)
// export const fetchDeviceRegistryData = async (query) => deviceList
export const addDevice = async (deviceData) => (
  await devicehubRequest.postRequest('device', 'registry')(deviceData)
)
export const deleteDevice = async (deviceId) => (
  await devicehubRequest.deleteRequest('device', 'registry', deviceId)()
)
export const updateDevice = async (deviceId) => (
  await devicehubRequest.putRequest('device', 'registry')(deviceId)
)
export const fetchDeviceHistoryData = async ({ deviceId, args }) => (
  await devicehubRequest.getRequest('device', deviceId, 'history')(args)
)
export const downloadCertificate = async (uuid) => (
  await devicehubRequest.getRequest('device', 'registry', 'security', 'certs', uuid)()
)
// device types
export const fetchDeviceTypes = async (query) => {
  return await devicehubRequest.getRequest('devicetype')(query)
}
export const addDeviceType = async ({ query, image }) => {
  return await devicehubRequest.postRequestFile('devicetype')(query, image)
}
export const editDeviceType = async ({ query, image }) => {
  return await devicehubRequest.putRequestFile('devicetype')(query, image)
}
export const deleteDeviceType = async (deviceTypeId) => {
  return await devicehubRequest.deleteRequest('devicetype', deviceTypeId)()
}
export const fetchProductCode = async (code) => {
  return await devicehubRequest.getRequest('devicetype', 'productCodes', code, 'search')()
}
// device profile
export const fetchDeviceProfileData = async (deviceId) => (
  await devicehubRequest.getRequest('device', 'profiles')(deviceId)
)
export const postDeviceProfileData = async (query) => (
  await devicehubRequest.postRequest('device', 'profiles')(query)
)
export const updateDeviceProfileData = async (query) => (
  await devicehubRequest.putRequest('device', 'profiles')(query)
)
export const addDeviceLocation = async (deviceLocation) => (
  await devicehubRequest.postRequest('device', 'locations')(deviceLocation)
)
export const addDeviceGroup = async (deviceGroup) => (
  await devicehubRequest.postRequest('device', 'customers')(deviceGroup)
)
export const searchDeviceLocation = async (searchLocationParams) => (
  await devicehubRequest.getRequest('device', 'locations')(searchLocationParams)
)
export const searchDeviceGroup = async (searchDeviceParams) => (
  await devicehubRequest.getRequest('device', 'customers')(searchDeviceParams)
)
export const searchDeviceGroupWithLocation = async (args) => (
  await devicehubRequest.getRequest('device', 'locations', args.id, 'customers')(args.query)
)

// opentron
export const runOpentron = async (args) => (
  await devicehubRequest.postRequest('device', 'opentron', 'runjob')(args)
)
export const validateOpentron = async (args) => (
  await devicehubRequest.postRequest('device', 'opentron', 'validate')(args)
)
export const showJobLogs = async (Id) => (
  await devicehubRequest.postRequest('device', 'opentron', 'log', Id)()
)
export const fetchJobsList = async (query) => (
  await devicehubRequest.getRequest('device', 'opentron')(query)
)
export const checkDeviceJobStatus = async (deviceId) => (
  await devicehubRequest.postRequest('device', 'opentron', deviceId)()
)

// Device Notifications
export const fetchDeviceNotificationFilters = async (deviceId) => (
  await devicehubRequest.getRequest('devices', 'notifications', 'filters', deviceId)()
)

export const fetchDeviceNotificationsList = async ({ query, deviceId }) => (
  await devicehubRequest.getRequest('devices', deviceId, 'notifications')(query)
)
export const fetchDeviceNotificationsDetails = async ({ deviceId, notificationId }) => (
  await devicehubRequest.getRequest('devices', deviceId, 'notifications', notificationId)()
)
export const fetchNotificationActions = async (categoryId) => (
  await devicehubRequest.getRequest('devices', 'notifications', 'categories', categoryId, 'actions')({ categoryId })
)
export const fetchNotificationCategories = async (query) => (
  await devicehubRequest.getRequest('devices', 'notifications', 'categories')(query)
)
export const fetchNotificationParams = async (categoryId) => (
  await devicehubRequest.getRequest('devices', 'notifications', 'categories', categoryId, 'params')({ categoryId })
)
export const createNotification = async (query) => (
  await devicehubRequest.postRequest('devices', 'notifications')(query)
)
export const saveRemoteSessionFeedback = async ({ query, notificationId }) => await devicehubRequest.putRequest('device', 'remote', 'session', notificationId)(query)
// devices
export const fetchDevices = async (query) => await devicehubRequest.getRequest('devices', 'view')(query)
export const fetchDeviceAssignments = async (query) => await devicehubRequestV1.getRequest('device-assignments')(query)
export const fetchDeviceAssignmentsFilters = async (query) => await devicehubRequestV1.getRequest('device-assignments-facets')(query)
export const fetchDeviceAssignmenFacetValues = async (query) => await devicehubRequestV1.getRequest('listing-facets')(query)
export const saveRegisterIotValues = async (query) => await devicehubRequest.putRequestWithQueryParams('device', 'registration')(query)
export const deviceCertificateDownloadIot = async ({ deviceId }) => { return await devicehubRequestV1.getRequest('devices', deviceId, 'device-cert')() }
export const uploadCsrFile = async (payload) => await devicehubRequest.postRequestWithQueryParams('device', 'registration', 'upload-csr')(payload)
export const sendOtpUpload = async (payload) => await userhubRequestV1.postRequestWithQueryParams('otp', 'send')(payload)

// Device Software Management
export const fetchDeviceSoftwareUpdateList = async (query) => await devicehubRequest.getRequest('software-update', 'devices')(query)
export const fetchDeviceSoftwareFilter = async () => await devicehubRequest.getRequest('software-update', 'devices', 'filters')()

export const fetchDeviceSoftwareUploadList = async (query) => await devicehubRequest.getRequest('software-update', 'uploadedSoftwares')(query)
export const fetchDeviceSoftwareUploadFilter = async () => await devicehubRequest.getRequest('software-update', 'uploadedSoftwares', 'filters')()
export const fetchSoftwareReleaseDetails = async (query) => await devicehubRequest.getRequest('software-update', 'list-single')(query)
export const fetchDeviceSoftwareConfig = async (deviceId) => await devicehubRequest.getRequest('software-update', 'state', 'devices', deviceId)()
export const updateDeviceSoftwareConfig = async ({ deviceId, payload }) => await devicehubRequest.patchRequest('software-update', 'state', 'devices', deviceId)(payload)
export const updateDeviceSoftware = async (payload) => await devicehubRequest.postRequest('software-update', 'notification')(payload)
export const fetchSerialNumber = async (deviceTypeId, productRangeId) => await devicehubRequest.getRequest('software-update', 'bulk')(deviceTypeId, productRangeId)
export const fetchProductRange = async (deviceTypeId) => await devicehubRequest.getRequest('software-update', 'product-ranges')(deviceTypeId)
export const fetchDeviceValidateSoftwareList = async (query) => await devicehubRequest.getRequest('software-update', 'validateSoftwares')(query)
export const fetchDeviceValidateSoftwareFilter = async () => await devicehubRequest.getRequest('software-update', 'validateSoftwares', 'filters')()
export const fetchValidateSoftwarePopupList = async (query) => await devicehubRequest.getRequest('devicesoftware', 'validate')(query)
export const fetchSoftwareStatusList = async () => await devicehubRequest.getRequest('devicesoftware', 'release', 'status')()
export const updateValidateSoftwareData = async (query) => await devicehubRequest.putRequestWithQueryParams('devicesoftware', 'validate')(query)
export const fetchDeviceSoftwareUpdateStatus = async (query) => await devicehubRequest.getRequest('software-update', 'status')(query)
export const generateSoftwareReport = async (payload) => await devicehubRequestV2.postRequest('soft-update', 'report')(payload)
export const generateCommercialReport = async (payload) => await devicehubRequest.postRequest('commercialReport', 'report')(payload)
export const fetchCountryOrganizations = async (payload) => await devicehubRequest.postRequest('commercialReport', 'customer', 'data')(payload)
// export const fetchFilters = async () => await devicehubRequest.getRequest('devices', 'facets')()
export const acknowledgementFailureEvent = async (deviceId) => await devicehubRequest.putRequest('software-update', 'status', 'consent', 'devices', deviceId)()

export const fetchFilters = async () => { }
export const getCustomers = async () => await devicehubRequest.getRequest('devices', 'filters')()
export const getDeviceTypesCustomers = async () => await devicehubRequest.getRequest('devicetype', 'filter')()

// Sent contact hotline
// export const sendContactHotline = async (payload) => contactHotlineList
export const sendContactHotline = async ({ query, file }) => await devicehubRequest.postRequestFile('device', 'hotline', 'chrequest')(query, file)

export const fetchHotlineUserRole = async () => await userhubRequest.getRequest('users', 'hotlineuser')()

export const fetchHotlineUserAccess = async () => await devicehubRequest.getRequest('device', 'hotline', 'hotlineaccess')()

// cards
export const createCard = async (card) => (
  await analyticsRequest.postRequest('cards')(card)
)
export const fetchCardData = async (card) => {
  return await analyticsRequest.postRequest('cardrunner')(card)
}
export const fetchAnalyticData = async (query) => (
  await analyticsRequest.getRequest('dataset')(query)
)
export const searchMetric = async ({ metric, name, query }) => (
  await analyticsRequest.getRequest(metric, name, 'values')(query)
)
export const analyticsPoll = async (payload) => (
  await analyticsRequest.postRequest('analytics', 'poll')(payload)
)

// Dummy API Calls
export const fetchEquipmentServiceRecords = async () => ({ data: [] })
export const fetchDeviceCertificate = async () => ({ data: [] })

// Cards
export const fetchCardsFacets = async () => await analyticsRequest.getRequest('cards', 'facets')()
export const fetchCards = async (query) => await analyticsRequest.getRequest('cards')(query)
export const fetchCardDetail = async (cardId) => await analyticsRequest.getRequest('cards', cardId)()
export const updateCardDetail = async ({ card, cardId }) => await analyticsRequest.putRequest('cards', cardId)(card)
export const deleteCard = async ({ id }) => await analyticsRequest.deleteRequest('cards', id)()

// Partner DashBoard
export const fetchPartnerDevicesOnSearch = async (query) => await partnerDashboardRequest.getRequest('partner-devices')(query)
export const fetchSystemStatus = async (deviceId) => await partnerDashboardRequest.getRequest('partner-devices', deviceId, 'status')()
export const createNotifications = async (payload) => await devicehubRequest.postRequestStream('notifications')(payload)
export const validateNotificationStatus = async (payload) => await devicehubRequest.postRequest('notifications', 'status')(payload)
export const createLogsStore = async (payload) => await devicehubRequest.postRequest('logstores', 'log')(payload)

// Device Details
export const fetchDeviceDetails = async ({ deviceId }) => await devicehubRequest.getRequest('devices', deviceId)()
export const getCategoriesId = async () => await devicehubRequest.getRequest('devicesoftware', 'notification', 'category')()

export const fetchDeviceCards = async (params) => {
  const { deviceTypeId, ...query } = params
  return await analyticsRequest.getRequest('devicetype', deviceTypeId, 'cards')(query)
}
export const fetchDataTypes = async (query) => await analyticsRequest.getRequest('datatypes')(query)
export const fetchPeripheralSuppliers = async (query) => await devicehubRequestV1.getRequest('partner-peripheral-suppliers')(query)
export const fetchNotificationCoordinator = async (payload) => (
  await devicehubRequest.postRequest('notificationcoordinator')(payload)
)
export const fetchPartnerPeripheralInformation = async ({ query, deviceId }) => (
  await devicehubRequest.getRequest('devices', deviceId, 'peripherals')(query)
)
export const addPartnerPeripheralInformation = async ({ deviceId, query }) => {
  return await devicehubRequest.postRequest('devices', deviceId, 'peripherals')(query)
}
export const updatePartnerPeripheralInformation = async ({ peripheralDeviceId, query }) => {
  return await devicehubRequest.putRequest('devices', 'peripherals', peripheralDeviceId)(query)
}
export const deletePartnerPeripheralInformation = async ({ peripheralDeviceId }) => (
  await devicehubRequest.deleteRequest('devices', 'peripherals', peripheralDeviceId)()
)
export const fetchDeviceSystemConfiguration = async (uuid) => await devicehubRequest.getRequest('system-installation-configuration')(uuid)
export const fetchDeviceConfig = async ({ deviceId }) => await devicehubRequestV2.getRequest('devices', deviceId, 'config')()
export const updateDeviceCardDetails = async ({ deviceId, values }) => await devicehubRequest.patchRequest('devices', deviceId)(values)

// File Management
export const fetchDocumentTypes = async () => (
  await filemanagementRequest.getRequest('v1', 'documentTypes', 'file')()
)
export const fetchDeviceDocumentTypes = async () => (
  await filemanagementRequest.getRequest('v1', 'documentTypes', 'device')()
)
export const fetchUploadDocumentTypes = async (query) => (
  await filemanagementRequest.getRequest('v1', 'documentTypes', 'upload')(query)
)
export const fetchDocumentTypeId = async (query) => (
  await filemanagementRequest.getRequest('v1', 'documentTypes', 'fetch')(query)
)
export const uploadFile = async (args) => (
  await filemanagementRequest.postRequestFile('v2', 'documents')(args.query, args.fileData)
)
export const fetchDeviceTypeFiles = async (query) => (
  await filemanagementRequest.getRequest('v2', 'documents')(query)
)
// export const fetchDeviceTypeFiles = async (query) => fileMgmtList

export const fetchFiles = async (queryParams) => (
  await filemanagementRequest.getRequest('v2', 'documents', 'deviceTypes', queryParams.deviceTypeId, 'devices', queryParams.deviceId)(queryParams.query)
)
export const removeItem = async (args) => (
  await filemanagementRequest.deleteRequest('v2', 'documents', args.documentId)()
)
export const downloadFile = async (args) => (
  await filemanagementRequest.getRequest('v2', 'documents', args.documentId)()
)
export const editFile = async (args) => (
  await filemanagementRequest.putRequestWithQueryParams('v2', 'documents')(args)
)
export const createSessionFileMgmt = async (args) => (
  await filemanagementRequest.postRequestWithQueryParams('v2', 'documents', 'session')(args)
)
export const generateConfigFile = async (args) => (
  await filemanagementRequest.postRequestWithQueryParams('v2', 'documents', 'generateConfigFile')(args)
)

// Global Alerts And Alarms
// export const fetchAlertsAndAlarms = async (query) => (
//   await devicehubRequestV2.getRequest('devices', 'events')(query)
// )
export const fetchAlertsAndAlarms = async (query) => (
  await devicehubRequestV2.getRequest('devices', 'eventAndFilter')(query)
)
export const fetchAlertsAndAlarmsFilters = async () => (
  await devicehubRequestV2.getRequest('devices', 'events', 'filters')()
)
export const fetchErrorNames = async ({ deviceId, severity }) => (await devicehubRequestV2.getRequest('devices', deviceId, 'alarmEvents')({ active: true, severity }))

export const getApplicationVersion = async () => (
  await appVersionRequest.getRequest('version')(Math.random())
)

export const fetchMaintenanceAlerts = async (query) => (
  await devicehubRequestV2.getRequest('devices', 'eventsAndFilters', 'operations')(query)
)
export const fetchMaintenanceAlertsFilters = async (query) => (
  await devicehubRequestV2.getRequest('devices', 'events', 'operations', 'filters')(query)
)
export const fetchActiveEvents = async ({ deviceId, ...query }) => (
  await analyticsRequest.getRequest('devices', deviceId, 'events')(query)
)
export const maintenanceSync = async (syncData) => (
  await devicehubRequestV2.postRequest('devices', 'maintenanceAlarm', 'sync')(syncData)
)

// Device certificate status dashboard
export const fetchDeviceCertificateFilters = async () => (
  await devicehubRequest.getRequest('v1', 'device-certs-facets')()
)
export const fetchDeviceCertificateDetail = async ({ deviceId, query }) => {
  return await devicehubRequest.getRequest('v1', 'devices', deviceId, 'certs')(query)
}
export const fetchDeviceCertificates = async (query) => (
  await devicehubRequest.getRequest('v1', 'device-certs')(query)
)

export const createRenewCertificateNotification = async (deviceId) => (
  await devicehubRequest.postRequest('v1', 'devices', deviceId, 'certs')()
)

export const downlaodDeviceCertificate = async ({ certificateType: type, deviceId }) => {
  return await devicehubRequest.getRequest('v1', 'devices', deviceId, 'cert')({ type })
}

// Device Remote Connection

export const fetchRemoteConnectionNotificationId = async (uuid) => (
  await devicehubRequest.getRequest('device', 'remote', 'notification', uuid)()
)
export const pollRemoteConnectionSessionId = async (notificationId) => (
  await devicehubRequest.getRequest('device', 'remote', 'status', notificationId)()
)
export const closeRemoteConnectionWindow = async ({ uuid, ...restProps }) => (
  await devicehubRequest.putRequestWithQueryParams('device', 'remote', uuid)({ ...restProps })
)
export const checkDeviceWindowStatus = async (uuid) => (
  await devicehubRequest.getRequest('device', 'remote', uuid)()
)
// Device Software upload

export const fetchSoftwareTypes = async () => (
  await filemanagementRequest.getRequest('v1', 'documentTypes', 'software')()
)
export const fetchDeviceSoftwareReleaseStatus = async () => {
  return await devicehubRequest.getRequest('devicesoftware', 'release', 'status')()
}
export const lastSoftwareReleaseDate = async () => await devicehubRequest.getRequest('devicesoftware', 'devicetype', 'release-date')()
export const geValidFileTypesAddDeviceSoftware = async () => {
  const query = { category: 'SOFTWARE_PACKAGE' }
  return await filemanagementRequest.getRequest('v2', 'documents', 'extension')(query)
}
export const getValidReleasenoteType = async () => {
  const query = { category: 'SOFTWARE_RELEASE_NOTES' }
  return await filemanagementRequest.getRequest('v2', 'documents', 'extension')(query)
}
export const addDeviceSoftware = async ({ query, formDataSoftwareImage, callBackFunc }) => {
  return await devicehubRequest.postRequestFile('devicesoftware', 'upload')(query, formDataSoftwareImage, tokenConstants.ACCESS_TOKEN, callBackFunc, 'axiosCall')
}
export const editDeviceSoftware = async (query) => {
  return await devicehubRequest.putRequestFile('devicesoftware', 'update')(query)
}
export const deleteDeviceSoftware = async (query) => {
  return await devicehubRequest.deleteRequest('devicesoftware', 'delete', query)()
}
export const cancelSoftwareUpdate = async (payload) => await devicehubRequest.postRequest('software-update', 'notification')(payload)

// Generate Quality Report
export const setQualityReportTimePeriod = async ({ uuid, params }) => (
  await devicehubRequestV2.getRequest('quality', 'devices', uuid, 'events')({ ...params })
)

// Contact Holtine DashBoard
export const fetchRegionsList = async () => (
  await userhubRequest.getRequest('regions')()
)
export const fetchCountriesListByRegionId = async (regionId) => (
  await userhubRequest.getRequest('countries', 'region', regionId)()
)
export const addContactDetails = async (contactData) => (
  await devicehubRequest.postRequest('device', 'hotline', 'countries')(contactData)

)
export const fetchContacthotlineFilters = async () => (
  await devicehubRequest.getRequest('device', 'hotline', 'filters')()
)
export const editContactDetails = async (contactData) => (
  await devicehubRequest.putRequest('device', 'hotline')(contactData)
)
export const fetchContactHotlineDashboardData = async (query) => (
  await devicehubRequest.getRequest('device', 'hotline')(query)
)
export const removeContactHotlineRecord = async (query) => (
  await devicehubRequest.deleteRequest('device', 'hotline', query?.countryId)()
)
export const fetchHotlineCategories = async (query) => (
  await devicehubRequest.getRequest('device', 'hotline', 'categories')(query)
)
export const fetchContactHotlineDetailsDashboard = async (query) => (
  await devicehubRequest.getRequest('device', 'hotline', 'view')(query)
)
export const editUserContactHotlineDetails = async (query) => (
  await devicehubRequest.getRequest('device', 'hotline', 'edit')(query)
)
export const getHotlineCategories = async () => (
  await devicehubRequest.getRequest('device', 'hotline', 'category')()
)

// User registration consolidated with pwd reset
export const getCountriesUserRegistrationConsolidated = async (query) => await userhubRequest.getRequest('countries')(query, tokenConstants.RESET_PASSWORD_TOKEN)
export const getCountriesZoneUserRegistrationConsolidated = async (query) => await userhubRequest.getRequest('countries', 'timezone')(query, tokenConstants.RESET_PASSWORD_TOKEN)
export const fetchAllLanguagesUserRegistration = async (query) => await userhubRequest.getRequest('languages')(query, tokenConstants.RESET_PASSWORD_TOKEN)
export const updateUserRegistrationConsolidatedDetails = async ({ user, userId }) => {
  return await userhubRequest.putRequest('users', userId)(user, tokenConstants.RESET_PASSWORD_TOKEN)
}
export const userRegistrationConsolidatedResetPassword = async ({ password }) => {
  return await userhubRequest.postRequest('users', 'password')(password, tokenConstants.RESET_PASSWORD_TOKEN)
}
export const geValidFileTypesFileManagement = async (param) => {
  const query = { category: param || 'FILE_UPLOAD' }
  return await filemanagementRequest.getRequest('v2', 'documents', 'extension')(query)
}
export const fetchInvitedUserInfo = async (query) => await userhubRequest.getRequest('userinfo')(query, tokenConstants.RESET_PASSWORD_TOKEN)
export const getTermsPrivacyInfo = async (query) => await userhubRequest.getRequest('legalpolicy')(query)
export const getTermsPrivacyInfoInvitedUser = async (query) => await userhubRequest.getRequest('legalpolicy')(query, tokenConstants.RESET_PASSWORD_TOKEN)
export const getPartnerTypesByBrandId = async (id) => await devicehubRequestV1.getRequest('partner-peripheral-suppliers', id)()

export const getPartnerTypes = async (query) => await devicehubRequest.getRequest('partner-type')(query)
export const fetchOrganizationTypes = async () => await userhubRequestV1.getRequest('orgs', 'types')()
export const fetchPartnerPeripheralsByDeviceId = async ({ deviceId }) => await devicehubRequest.getRequest('devices', 'partnerBrands', deviceId)()
export const fetchOrganizationFilters = async () => await userhubRequestV1.getRequest('orgs', 'filter')()
export const getBrandValuesByPartnerType = async (query) => await devicehubRequestV1.getRequest('partner-peripheral-suppliers', 'brandsForPartnerTypes')(query)
// Manage News Feed
export const getFilterCategory = async () => await devicehubRequest.getRequest('newsfeed')()
export const fetchNewsFeedTemplate = async (query) => await devicehubRequest.getRequest('newsfeed', 'templates')(query)
export const fetchNewsImages = async ({ categoryId, templateId }) => {
  const query = {
    templateId
  }
  return await devicehubRequest.getRequest('newsfeed', categoryId, 'images')(query)
}
export const fetchNewsFeedFacets = async () => await devicehubRequest.getRequest('newsfeed', 'getFilters')()
export const fetchNewsFeed = async (query) => await devicehubRequest.getRequest('newsfeed', 'templates')(query)
export const getNewsCategory = async () => await devicehubRequest.getRequest('newsfeed')()
export const addNewsFeedTemplate = async ({ query, file }) => await devicehubRequest.postRequestFile('newsfeed')(query, file)
export const editNewsFeedTemplate = async ({ query, file }) => await devicehubRequest.putRequestFile('newsfeed')(query, file)
export const deleteNewsFeed = async (templateNo) => await devicehubRequest.deleteRequest('newsfeed', templateNo)()
export const getActiveNewsFeedCate = async () => await devicehubRequest.getRequest('newsfeed', 'activetemplates')()
export const getInitialAlarmAlertInfo = async (deviceId) => await devicehubRequest.getRequest('device', 'alarm-alert', 'config', deviceId)()
export const setAlarmAlertInfo = async ({ deviceId, query }) => await devicehubRequest.postRequest('device', 'alarm-alert', 'config', deviceId)(query)
export const sequenceOredrValidation = async (query) => await devicehubRequest.getRequest('newsfeed', 'sequence')(query)
export const getApplicationDomain = async ({ query, token }) => await userhubRequest.getRequest('business-domain')(query, token)

// Register User
export const registerUser = async (user) => await userhubRequest.postRequest('self-registration')(user, null)
export const fetchRolesWithoutAuth = async () => await userhubRequest.getRequest('self-registration', 'labwater', 'role-of-interest')()
export const fetchCountriesWithoutAuth = async () => await userhubRequest.getRequest('self-registration', 'countries')()
export const validateSelfReg = async (user) => await userhubRequest.postRequest('self-registration', 'self-registration-validation')(user, null)

// Validate User
export const validateUser = async (query) => await userhubRequest.putRequestWithQueryParams('self-registration', 'validate')(query)

// user feedback
export const postUserFeedback = async (feedback) => await userhubRequest.postRequest('feedback')(feedback)
export const getAllUserFeedback = async () => await userhubRequest.getRequest('feedback')()
export const getUserFeedback = async (userId) => await userhubRequest.getRequest('feedback', userId)()
export const getRatingImprovementList = async (query) => await userhubRequest.getRequest('feedback', 'all')(query)
export const getRatingFilter = async () => await userhubRequest.getRequest('feedback', 'facets')()
export const getOverallFeedback = async ({ query, userId }) => await userhubRequest.getRequest('feedback', 'ratings', userId)(query)
export const fetchFeedbackTrendValues = async (duration) => await userhubRequest.getRequest('feedback', 'chart', 'feedbacktrend')(duration)
export const fetchRatingTrendValues = async (duration) => await userhubRequest.getRequest('feedback', 'chart', 'ratingtrend')(duration)
export const generateUserFeedbackReport = async (payload) => await devicehubRequest.postRequest('feedback', 'report')(payload)
export const getfeedbackDefaultersData = async (query) => await userhubRequest.getRequest('feedback', 'defaulters')(query)
export const feedbackDefaulterFilters = async () => await userhubRequest.getRequest('feedback', 'defaulters', 'facets')()
export const setDispenseReportPeriod = async (query) => await devicehubRequest.postRequest('dispense-event-report')(query)
export const LoadPartnerPeripheralFilter = async () => (
  await devicehubRequest.getRequest('partner-peripheral-brand', 'getFilters')()
)
export const getPartnerPeripherallist = async (query) => await devicehubRequest.getRequest('partner-peripheral-brand')(query)
export const createPartnerBrand = async (query) => await devicehubRequest.postRequestWithQueryParams('partner-peripheral-brand')(query)
export const updatePartnerBrand = async (query) => await devicehubRequest.putRequestWithQueryParams('partner-peripheral-brand')(query)
export const deletePartnerPeripheral = async (brandId) => {
  return await devicehubRequest.deleteRequest('partner-peripheral-brand', brandId)()
}
export const validateDeviceSoftwareBeforeAdding = async (query) => await devicehubRequest.getRequest('devicesoftware', 'upload', 'validate')(query)
// fetch device detail buttons
export const fetchDeviceDetailsBtn = async (menuGroupName) => await userhubRequest.getRequest('usermenu', menuGroupName)()
// Set Device details buttons
export const saveDeviceDetailsBtn = async (data) => await userhubRequest.putRequest('usermenu', 'mangement')(data)
export const downLoadDocumentCertificate = async ({ docId }) => await filemanagementRequest.getRequest('v2', 'documents', docId)()

//News Category Management

export const getNewsCategories = async (query) => await devicehubRequest.getRequest('newsfeed', 'newscategories')(query)
export const addNewsCategory = async (newsCategory) => await devicehubRequest.postRequest('newsfeed', 'newscategories')(newsCategory)
export const editNewsCategory = async (query) => await devicehubRequest.putRequest('newsfeed', 'newscategories', query?.categoryId)(query)
export const deleteNewsCategory = async (categoryId) => await devicehubRequest.deleteRequest('newsfeed', 'newscategories', categoryId)()

//Device Access Request
export const addNewDeviceRequest = async (query) => await devicehubRequestV1.postRequest('device-access-request', 'add')(query)
export const deviceAccessRequestList = async (query) => await devicehubRequestV1.getRequest('device-access-request', 'requested-devices')(query)
export const deviceAccessRequesterList = async (query) => await devicehubRequestV1.getRequest('device-access-request', 'requests_for_requester')(query)
export const deviceRequestListSubmit = async (query) => await devicehubRequestV1.postRequest('device-access-request', 'submit')(query)
// Device Assignment List Page
export const deviceAssignmentListPage = async (query) => await devicehubRequestV1.getRequest('device-access-request', 'requests_for_approver')(query)
export const fetchApproverfilters = async () => await devicehubRequestV1.getRequest('device-access-request', 'filters')()
export const deviceAssignmentValidation = async (query) => await devicehubRequestV1.putRequestWithQueryParams('device-access-request','validate')(query)

//Service Contract Management
export const getServiceContracts = async (query) => await devicehubRequestV1.getRequest('service-contracts')(query)
export const getCoveredProducts = async ({ query, serviceContractId }) => await devicehubRequestV1.getRequest('service-contracts', serviceContractId, 'covered-products')(query)
export const getConsumables = async ({ serviceContractId }) => await devicehubRequestV1.getRequest('service-contracts', serviceContractId, 'consumables')()
export const getOptionsIncluded = async ({ query, serviceContractId }) => await devicehubRequestV1.getRequest('service-contracts', serviceContractId, 'services')(query)
export const getUpdatePlans = async ({ query, serviceContractId }) => await devicehubRequestV1.getRequest('service-contracts', serviceContractId, 'covered-products')(query)
export const updatePlans = async (query) => await devicehubRequestV1.postRequest('service-contracts', query?.serviceContractId, 'update-plan')(query)
export const updatePMVisit = async (query) => await devicehubRequestV1.postRequest('service-contracts', query?.serviceContractId, 'covered-products', 'update-pm-visits')(query)
export const getServiceLevels = async (query) => await devicehubRequestV1.getRequest('service-contracts', 'covered-products', 'service-levels')(query)

//Data Analysis
export const fetchUsereDataAnalysis = async (data) => await dataAnalysisRequest.getRequest('user-profile','active-user-by-role')()
export const generateUserReport = async (data) => await dataAnalysisRequest.getRequest('report')(data)
export const getRemoteControlChartData = async (data) => await dataAnalysisRequest.getRequest('data-analysis')(data)
export const fetchKpiFilters = async (data) => await dataAnalysisRequest.getRequest('filter','data-filters')()
export const fetchMapFilters = async (data) => await dataAnalysisRequest.getRequest('filter','map-filters')()
export const getWorldMapData = async (data) => await dataAnalysisRequest.getRequest('data-analysis','map-data')(data)

//How To
export const getTypeOfSubject = async () => await howToRequest.getRequest('field-of-interest', 'upload')()
export const getHowTo = async (query) => await howToRequest.getRequest('fetch')(query)
export const validatedVideo = async (query) => await howToRequest.getRequest('validation')(query)
export const addHowToVideo = async ({query, file}) => await howToRequest.postRequestFile('add')(query, file)
export const editHowToVideo = async (query) => await howToRequest.putRequest('update')(query)
export const deleteHowToVideo = async (videoId) => await howToRequest.deleteRequest('delete', videoId)()
export const welcomeVideo = async () => await howToRequest.getRequest('welcome-video')()
export const fetchHowToFilters = async () => await howToRequest.getRequest('filters')()

//Notification Center
export const getNewsCenter = async (query) => await newsCenter.getRequest('fetchNotificationByCriteria')(query)
export const addNewsFeed = async (query) => await newsCenter.postRequest('saveNotification')(query)
export const editNewsFeed = async (query) => await newsCenter.putRequest('updateNotification', query?.notificationId)(query)
// User Notification
export const getUserNotificationCount = async () => await userNotification.getRequest('read-count')()
export const getUserNotifcationList = async (query) => await userNotification.getRequest('notification-users')(query)
export const updateUserNotifcation = async (query) => await userNotification.putRequest(query)()

