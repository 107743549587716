import React, { useEffect } from 'react'
import './Notificationlist.scss'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import UserNotificationCard from './NotificationCard'
import mainRoutes from 'constants/mainroutes'


const NotificationList = (props) =>{
  const location = useLocation()
  const history = useHistory()
  const {selectedNotifcnId, getUserNotifcationList, handleNotification, setShowDetails, showDetails} = props

  useEffect(() => {
    if(location.pathname === mainRoutes.NOTIFICATION_HISTORY){
      setShowDetails(false)
      if (history?.action === 'POP') {
        getUserNotifcationList()
      }
    }else{
      setShowDetails(true)
    }
  }, [location])

  const notfcnData = props?.listData?.toJS()
  const notfcnList = notfcnData?.map((item) =>{
    const {notificationId: {notificationSubject, type, notificationCreatedDate, notificationContent}} = item || ''
    const notficationClass = `notfication-lst ${!item?.isRead ? 'active' : ''} ${selectedNotifcnId === item?.id ? 'highlight' : ''}`
    return (
              <li
                className={notficationClass}
                key={item.id}
                onClick={() => handleNotification(item)}>
                  {notificationSubject && <React.Fragment>
                      <UserNotificationCard
                        subject={notificationSubject}
                        type={type}
                        details={notificationContent}
                        timestamp={notificationCreatedDate}
                        showDetails={showDetails}
                      />
                    </React.Fragment>
                  }
              </li>
           )}
  )

  return (
    notfcnData?.length > 0 &&
    <div className='list-div'>
      <ul>
        {notfcnList}
      </ul>
    </div>
  )
}

NotificationList.propTypes = {
  handleNotification: PropTypes.func,
  listData: PropTypes.object,
  getUserNotifcationList: PropTypes.func,
  selectedNotifcnId: PropTypes.number,
  setShowDetails: PropTypes.func,
  showDetails: PropTypes.bool
}

export default NotificationList
